#chat1 .form-outline .form-control~.form-notch div {
    pointer-events: none;
    border: 1px solid;
    border-color: #eee;
    box-sizing: border-box;
    background: transparent;
    }
    
    #chat1 .form-outline .form-control~.form-notch .form-notch-leading {
    left: 0;
    top: 0;
    height: 100%;
    border-right: none;
    border-radius: .65rem 0 0 .65rem;
    }
    
    #chat1 .form-outline .form-control~.form-notch .form-notch-middle {
    flex: 0 0 auto;
    max-width: calc(100% - 1rem);
    height: 100%;
    border-right: none;
    border-left: none;
    }
    
    #chat1 .form-outline .form-control~.form-notch .form-notch-trailing {
    flex-grow: 1;
    height: 100%;
    border-left: none;
    border-radius: 0 .65rem .65rem 0;
    }
    
    #chat1 .form-outline .form-control:focus~.form-notch .form-notch-leading {
    border-top: 0.125rem solid #3AB9C0;
    border-bottom: 0.125rem solid #3AB9C0;
    border-left: 0.125rem solid #3AB9C0;
    }
    
    #chat1 .form-outline .form-control:focus~.form-notch .form-notch-leading,
    #chat1 .form-outline .form-control.active~.form-notch .form-notch-leading {
    border-right: none;
    transition: all 0.2s linear;
    }
    
    #chat1 .form-outline .form-control:focus~.form-notch .form-notch-middle {
    border-bottom: 0.125rem solid;
    border-color: #3AB9C0;
    }
    
    #chat1 .form-outline .form-control:focus~.form-notch .form-notch-middle,
    #chat1 .form-outline .form-control.active~.form-notch .form-notch-middle {
    border-top: none;
    border-right: none;
    border-left: none;
    transition: all 0.2s linear;
    }
    
    #chat1 .form-outline .form-control:focus~.form-notch .form-notch-trailing {
    border-top: 0.125rem solid #3AB9C0;
    border-bottom: 0.125rem solid #3AB9C0;
    border-right: 0.125rem solid #3AB9C0;
    }
    
    #chat1 .form-outline .form-control:focus~.form-notch .form-notch-trailing,
    #chat1 .form-outline .form-control.active~.form-notch .form-notch-trailing {
    border-left: none;
    transition: all 0.2s linear;
    }
    
    #chat1 .form-outline .form-control:focus~.form-label {
    color: #3AB9C0;
    }
    
    #chat1 .form-outline .form-control~.form-label {
    color: #bfbfbf;
    }
    .scroll-body {
      max-height: 300px; /* Adjust the height as needed */
      overflow-y: auto;
      padding-right: 10px; /* Add padding to account for scrollbar width */
  }
  