.box-div{
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 10px;
}
.custom-text-align {
    text-align: right !important;
    justify-content: right !important;
    width: 100%;
    color: inherit; /* Set the color to inherit from parent */
    text-decoration: none;
}
.icon{
    font-size: 30px;
    color: #3BA9C0;
}