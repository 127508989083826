.seller .sign-up-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300vh;
}

.seller .sign-up-content {
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.seller .sign-up-title {
  text-align: center;
  margin-bottom: 20px;
}

.seller .input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.seller .input-icon {
  margin-right: 10px;
}

.seller .error-message {
  color: red;
  margin-top: 5px;
}

.seller .btn-primary {
  background-color: #42bcc2;
  border-color: #42bcc2;
}

.seller .btn-primary:hover {
  background-color: #3db583;
  border-color: #3db583;
}

.seller .sign-in-link {
  text-align: center;
  margin-top: 20px;
}

.seller .sign-in-link a {
  color: #42bcc2;
}
